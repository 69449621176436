import Vue from "vue";

/*TITLE*/
document.title = "Residencial Guadiana | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Residencial Guadiana";
Vue.prototype.$subtitle = "Tu casa es nuestra prioridad";

/*INTRO*/
Vue.prototype.$promoter = "Residencial Guadiana";
Vue.prototype.$introSubtitle = "Tu casa es nuestra prioridad";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "smart-vancouver-brown--20230127080141.jpg";
Vue.prototype.$image_kitchen = "pavimento-nast-grey-revestimiento-crystal-white-frentes-roble-organico-encimera-compac-ceniza--20230127080136.jpg";
Vue.prototype.$image_bath1 = "6-bathroom1--20221124091616.jpeg";
Vue.prototype.$image_bath2 = "7-bathroom2--20221124091616.jpeg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "smart-vancouver-brown--20230127080141.jpg",
  },
  {
    src: "smart-tanzania-white--20230127080149.jpg",
  },
  {
    src: "smart-tanzania-almond--20230127080157.jpg",
  },
  {
    src: "ac4-residence-1l-utah--20230127080107.jpg",
  },
  {
    src: "ac4-residence-1l-misuri--20230127080115.jpg",
  },
  {
    src: "ac4-residence-1l-arizona--20230127080123.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "pavimento-nast-grey-revestimiento-crystal-white-frentes-roble-organico-encimera-compac-ceniza--20230127080136.jpg",
  },
  {
    src: "pavimento-nast-grey-revestimiento-crystal-white-frentes-grafitti-encimera-compac-plomo--20230127080145.jpg",
  },
  {
    src: "pavimento-nast-caliza-revestimiento-dover-antique-china-blanco-frentes-blanco-nubol-encimera-compac-luna_fija--20230127080157.jpg",
  },
  {
    src: "pavimento-nast-brown-revestimiento-japan-blanco-frentes-bison-encimera-compac-ceniza--20230127080109.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "6-bathroom1--20221124091616.jpeg",
  },
  {
    src: "7-bathroom1--20221124091616.jpeg",
  },
  {
    src: "8-bathroom1--20221124091616.jpeg",
  },
  {
    src: "banyo-1_pavimento-bottega-caliza-revestimiento-base-bottega-caliza-revestimiento-decorativo-spiga-bottega-caliza-opc.1-dock-blanco-brillo-120--20230127080131.jpg",
  },
  {
    src: "banyo-1_pavimento-dover-acero-revestimiento-base-dover-acero-revestimiento-decorativo-dover-modern-line-acero-opc.1-dock-roble-negro-120--20230127080141.jpg",
  },
  {
    src: "banyo-1_pavimento-rodano-caliza-revestimiento-base-rodano-caliza-revestimiento-decorativo-mosaico-rodano-caliza-opc.1-dock-blanco-brillo-120--20230127080153.jpg",
  },
  {
    src: "banyo-1_pavimento-rodano-caliza-revestimiento-base-rodano-caliza-revestimiento-decorativo-mosaico-rodano-caliza-opc.1-dock-roble-negro-120--20230127080102.jpg",
  },
  {
    src: "banyo-1_pavimento-ferroker-platino-revestimiento-base-ruggine-platino-revestimiento-decorativo-ruggine-opc.1-dock-blanco-brillo-120--20230127080110.jpg",
  },
  {
    src: "banyo-1_pavimento-madagascar-blanco-revestimiento-base-madagascar-blanco-revestimiento-decorativo-ona-blanco-opc.1-dock-roble-negro-120--20230127080122.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "7-bathroom2--20221124091616.jpeg",
  },
  {
    src: "8-bathroom2--20221124091616.jpeg",
  },
  {
    src: "9-bathroom2--20221124091616.jpeg",
  },
  {
    src: "banyo-2_pavimento-urban-acero-nature-revestimiento-base-urban-acero-nature-revestimiento-decorativo-cubik-urban-acero-nature--20230127080137.jpg",
  },
  {
    src: "banyo-2_pavimento-rodano-caliza-revestimiento-base-rodano-caliza-revestimiento-decorativo-mosaico-rodano-caliza--20230127080148.jpg",
  },
  {
    src: "banyo-2_pavimento-ferroker-platino-revestimiento-base-ruggine-platino-revestimiento-decorativo-ruggine--20230127090100.jpg",
  },
  {
    src: "banyo-2_pavimento-dover-acero-revestimiento-base-dover-acero-revestimiento-decorativo-dover-modern-line-acero--20230127090119.jpg",
  },
  {
    src: "banyo-2_pavimento-bottega-caliza-revestimiento-base-bottega-caliza-revestimiento-decorativo-spiga-bottega-caliza-mueble-roble-root--20230127090130.jpg",
  },
  {
    src: "banyo-2_pavimento-bottega-caliza-revestimiento-base-bottega-caliza-revestimiento-decorativo-spiga-bottega-caliza--20230127090146.jpg",
  },
  {
    src: "banyo-2_pavimento-bottega-caliza-revestimiento-base-bottega-caliza-revestimiento-decorativo-spiga-bottega-caliza-mueble-roble-root--20230127090155.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/RESIDENCIAL_GUADIANA/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/yZR0AOwY_-U";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20de%20londres%2032d%20torrejón%20de%20ardoz%20madrid",
    text: "Calle de Londres, 32D. Torrejón de Ardoz, Madrid",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20de%20londres%2032d%20torrejón%20de%20ardoz%20madrid",
    text: "Calle de Londres, 32D. Torrejón de Ardoz, Madrid",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:916616767",
    text: "916616767",
  },
  {
    icon: "mdi-email",
    link: "mailto:onoeda@grupoferrocarril.com",
    text: "onoeda@grupoferrocarril.com",
  },
];
